import React from "react";
import { useState, useEffect, useMemo } from "react";
import ListTable from "../utils/Table";
import { all_successfull_transaction } from "./../../actions/transaction";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Export from "./../utils/Export";
export default function AllTransaction() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [isEdit, setIsEdit] = useState(false);
  const [totalAmount, setTotelAmount] = useState("");
  const [filter, setFilter] = useState("");
  const [open, setOpen] = useState(false);

  const [form, setForm] = useState({});

  const [tableData, setTableData] = useState({
    tableTitle: "All Transactions",
    disableDelete: true,
    column_sum: {
      name: "payment_amount",
      title: "Total Amount",
    },
    tableHead: [
      // {
      //   id: "name1",
      //   label: "Name",
      //   // link: "/Customer/CustomerDetail/CustomerDetail",
      //   color: "var(--gradientColor2)",
      // },
      {
        id: "email",
        label: "Customer Info",
        subText: "mobile_no",
      },
      {
        id: "plan",
        label: "Plan",
        isSpecial: true,
        align: "left",
      },
      {
        id: "payment_amount",
        label: "Amount",
      },
      {
        id: "payment_id",
        label: "Payment ID",
        subText: "payment_method",
      },
      {
        id: "date",
        label: "Date",
      },

      {
        id: "device_type",
        label: "Device Type",
        subText: "device_id",
      },
      {
        id: "location",
        label: "Location",
      },
      {
        id: "payment_status",
        label: "Status",
        isButtonDisplay: true,
      },
    ],
    tableBody: [],
    filterColumn: [
      //   {
      //     id: "1",
      //     title: "Status",
      //     name: "payment_status",
      //     displayOn: "category_type",
      //     // value: totalAmount,
      //     // default: location?.state,
      //     options: ["Failed", "Success"],
      //   },
    ],
    isDateRangeFilter: "date",
  });

  const transactions = useSelector(
    (state) => state?.transactions?.alltransactionslist
  );
  // console.log(transactions, "tttt");
  // console.log(tableData.filterColumn[0]?.value,"oooppp")
  useEffect(() => {
    dispatch(all_successfull_transaction());
  }, []);
  const handleClose = () => {
    setOpen(false);
  };
  useMemo(() => {
    if (transactions?.statuscode == 200) {
      const temp = tableData;
      temp.tableBody = transactions?.data.map((ele) => ({
        ...ele,
        id: ele?.user?.id,
        payment_status: ele?.status,
        // deviceID: ele?.user?.deviceId,
        mobile_no: ele?.mobile_no != null ? ele?.mobile_no : " - ",
        movie: ele?.movie?.movie_name,
        series: ele?.series?.series_name,
        plan_name: ele?.plan?.plan_name,

        payment_amount: parseFloat(ele?.payment_amount).toFixed(2),
        // plan: ele?.plan !== null ? ele?.plan?.plan_name : ele?.movie !== null ? ele?.movie?.movie_name : ele?.series?.series_name ,
        plan:
          ele?.transaction_type == "SVOD" ? (
            <p style={{ color: "var(--themeFontColor)" }}>
              {ele?.plan?.plan_name}
            </p>
          ) : ele?.movie ? (
            <>
              <p style={{ color: "var(--themeFontColor)" }}>
                {ele?.transaction_type}
              </p>
              <span style={{ color: "#dd5107" }}>
                ({ele?.movie?.movie_name})
              </span>
            </>
          ) : (
            <>
              <p style={{ color: "var(--themeFontColor)" }}>
                {ele?.transaction_type}
              </p>
              <span style={{ color: "#dd5107" }}>
                ({ele?.series?.series_name})
              </span>
            </>
          ),
        // movie: ele?.movie?.movie_name,
        // name1:
        //   ele?.name !== "-" ? (
        //     <Link
        //       to="/Customer/CustomerDetail/CustomerDetail"
        //       state={{ id: ele?.user_id }}
        //     >
        //       <p style={{ fontWeight: "700", color: "black" }}>{ele?.name}</p>
        //     </Link>
        //   ) : (
        //     <>
        //       <p
        //         onClick={() => setOpen(true)}
        //         style={{ fontWeight: "700", color: "red" }}
        //       >
        //         User deleted
        //       </p>
        //     </>
        //   ),
      }));

      //   temp.filterColumn[0]["options"] = [
      //     ...new Set(
      //       transactions?.data.map((ele) =>
      //         ele.plan?.status === "Active" ? ele?.plan?.plan_name : ""
      //       )
      //     ),
      //   ];

      setTableData({ ...temp });
    }
  }, [transactions]);

  const user = useSelector((state) => state.layout.profile);
  const movies = useSelector((state) => state.movies.movies);
  const series = useSelector((state) => state.webseries.series);

  //   useEffect(() => {
  //     const data = new FormData();
  //     data.append("id", user?.id);
  //     dispatch(all_series_list(data));
  // 	dispatch(all_movie_list(data));
  //   }, [user]);
  // console.log( tableData.filterColumn["category_type"],"159159")
  //   useMemo(() => {
  // 	const temp = tableData;
  // if(temp.filterColumn[0]["value"] ="Video" )
  // 	temp.filterColumn[0]["options"]
  //   }, []);

  return (
    <>
      <Export
        fileName={"All Transactions"}
        exportData={tableData?.exportData || tableData?.tableBody}
        headings={tableData.tableHead.map((value) => value.label)}
      />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert severity="info" variant="filled" color="success">
          You can not view user details because the user is already deleted
        </Alert>
      </Snackbar>

      <ListTable
        tableData={tableData}
        key={"ListTable"}
        setTableData={setTableData}
        setForm={setForm}
        setIsEdit={setIsEdit}
      />
    </>
  );
}
